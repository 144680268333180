<template>
  <div class="home">
    <el-card class="card">
      <div class="t1">邮箱验证成功</div>
      <img src="../../assets/image/login/dui.png" class="img" alt="" />
      <div class="t2">验证成功</div>
      <div class="t3" @click="goPath">前往登录</div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {};
  },
  methods: {
    goPath() {
      this.$router.push({name:'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 838px;
  height: 504px;
  background: #ffffff;
  padding: 50px;
  box-sizing: border-box;
  .t1 {
    width: 100%;
    font-size: 28px;
    font-weight: bolder;
    text-align: center;
  }
  .img {
    width: 150px;
    height: 150px;
    margin: 50px auto;
    display: block;
  }
  .t2 {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .t3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #67adf7;
    cursor: pointer;
    margin-top: 10px;
  }
}
</style>
